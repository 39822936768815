<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
        <v-btn tile :color="variables.colorPrimary" dark class="mb-2 modal-cadastro-btn-cadastrar" @click="openModal"> Enviar sms </v-btn>
        <v-col>
        </v-col>
        <v-col  cols="2">
          <v-select
            v-model="tipoBusca"
            :items="listaTipoBusca"
            menu-props="auto"
            hide-details
            label="Tipo busca"
            single-line
          ></v-select>
        </v-col>
      <v-col cols="2">
          <!-- <v-file-input v-model="file" class="file mt-5 ml-5" placeholder="Arquivo"></v-file-input> -->
            <v-file-input v-model="file" :readonly="false" class="file mt-5 ml-5"  label="Arquivo"></v-file-input>
      </v-col>
    </template>

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-form>
        <v-card-text class="modal-cadastro-form">
          <v-card-title>
            Enviar SMS
          </v-card-title>
        </v-card-text>
        <v-card-text class="modal-cadastro-form">
         <v-textarea
          counter
          label="Text"
          :error-messages="errorsMessage "
          v-model="form.message"
        ></v-textarea>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn v-if="permiteAcao($route, 'add')" type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn">Enviar</v-btn>
        </v-card-actions>
        
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { required, } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import * as convertCvs from 'papaparse'
import { map, size } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
export default {
  name: 'ModalSmsMensagem',
  data: () => ({
    activeModal: false,
    form: {},
    file: null,
    msgAcentuada: false,
    tipoBusca: 6,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'ESTABELECIMENTO ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ]
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapState({ numeros: state => state.SendSms.numeros }),
    variables: () => variables,
    errorsMessage () {
      const errors = []
      if (!this.$v.form.message.$dirty) return errors
      !this.$v.form.message.required && errors.push('Digite uma mensagem')
      if (size(this.numeros) === 0) errors.push('Selecione pelomenos um contato ou envie um arquivo')
      if (errors.length === 0) return ''
      return errors
    }
  },
  mounted () {
    Events.$on('Daodos::modal::sms', () => { this.activeModal = true })
  },
  watch: {
    tipoBusca (v) {
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    file (val) {
      this.convertCvs(val)
    }
  },
  methods: {
    ...mapActions('SendSms', ['sendSms', 'addFiltroAtual', 'addDadosTable']),
    ...mapActions('SendSms', { getItensEntities: 'obterItens' }),
    convertCvs (val) {
      // var lines= val.split("\n");
      // eslint-disable-next-line
      const vm = this
      let arquivo = convertCvs.parse(val, {
        config: {
          header: true,
          encoding: "utf-8"
        },
        complete: (results) => {
          // eslint-disable-next-line
          vm.clear()
          setTimeout(() => {
            let dados = vm.formatCvsFile(results)
            vm.addDadosTable(dados)
          }, 500)
        }
      })
      console.log(arquivo)
    },
    openModal () { this.activeModal = true },

    filtroEntities (v = false) {
      const vm = this
      this.getItensEntities({
        pageSize: this.$store.getters.ItensPaginacao,
        _filter: { "entityTypeId_In":`${ !v ? this.tipoBusca : v}` }
      }).then(() => {
        vm.loadingTable = false
      }).catch(e => {
        errorSnackbar(e.message)
      })
    },
    formatCvsFile ({data}) {
      if (size(data) < 1) return false
      let array = []
      // chaves no header do arquivo
      let keys  = data[0]
      // eslint-disable-next-line
      let dados = map(data, (v, k) => {
        if (k > 0) { // pula a a posição 0 que é o as chaves do arquivo
          let obj = {}
          v.forEach((value, chave) =>{
            obj[keys[chave]] = value
          })
          array.push(obj)   
        }
      })
      return array
    },
    clear() {
      Events.$emit('sms:clearTable')
    },
    submit () {
      if (this.$v.form.message.$invalid || size(this.numeros) === 0) {
        this.$v.form.$touch()
        return false
      }

      let dados = this.formatDados()
      this.sendSms(dados).then(() => {
        this.form.message = ''
        successSnackbar('Sms enviados com sucesso !')
        this.closeModal()
        Events.$emit('sms:clearTable')
      }).catch(e => {
        errorSnackbar(e.message)
      })
      this.msgAcentuada = false
    },
    formatDados () {
      let { form } = this
      let dados = this.numeros
      let text = String(form.message).split(' ')
      let regex = /[áàâãéèêíïóôõöúçñ/]/g
      let existeAcento = false
      
      text.forEach(nome => {
        let valido = nome.split(/ +/).every(parte => regex.test(parte))
        if (valido) { existeAcento = true }
      })

      if (size(dados)=== 0) return false
      // formata dados que vem da tabela ou por arquivo cvs, nunca dos dois
      dados = map(dados, v => ({
        number: 55 + String(v.numero || (`${v.ddd}${v.cellphone}`)).replace(/\s/g, ''),
        msg: form.message, code: existeAcento ? 8 : 0
      }))

      return dados
    },
    closeModal () {
      this.activeModal = false
    }
  },
  validations () {
    return {
      form: {
        message: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/components/modal-cadastro';
</style>
